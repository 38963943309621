import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Card, CardActionArea, CardContent } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import { LocationManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spaced: {
      margin: theme.spacing(1),
    },
    rightIcon: {
      position: 'absolute',
      right: theme.spacing(2),
      top: 32,
    },
    inactive: {
      color: theme.palette.common.neutral,
    },
    inactiveSmall: {
      color: theme.palette.common.neutralDark,
    },
    spacedCard: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const LocationCard: React.FunctionComponent<{
  location: LocationManager.Location.LocationAdminAPI.Location;
  onClick: (location: LocationManager.Location.LocationAdminAPI.Location) => void;
}> = ({ location, onClick }) => {
  const classes = useStyles();
  return (
    <Card elevation={0} className={classes.spacedCard} key={location.location_name}>
      <CardActionArea onClick={() => onClick(location)}>
        <CardContent>
          <ChevronRight className={classes.rightIcon} />
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={location.status === 'active' ? '' : classes.inactive}
          >
            {location.location_name}
          </Typography>
          {location.status === 'active' && (
            <Typography variant="body2" color="textSecondary" component="p">
              Assigned to {location.observer_count || 0} observer{location.observer_count === 1 ? '' : 's'},{' '}
              {location.validator_count || 0} validator{location.validator_count === 1 ? '' : 's'}
            </Typography>
          )}
          {location.status !== 'active' && (
            <Typography variant="body2" component="p" className={classes.inactiveSmall}>
              Inactive
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default LocationCard;
