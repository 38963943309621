import React, { useEffect, useCallback, useState, useRef } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CircularProgress, Theme } from '@material-ui/core';

import { View } from 'ol';
import { Style, Icon } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { FeatureLike } from 'ol/Feature';

import config from 'config';
import { LocationManager } from 'models';
import { useAppSelector, useAppDispatch } from 'hooks';
import { LocationActions } from 'state/location';
import { usePrevious } from 'hooks';
import { MapCommand, ZoomToCoords, ZoomToBounds, AddMarkers } from 'components/map/MapCommands';
import { getUserState } from 'utils';

import NewLocationComponent from 'components/locationadmin/NewLocationComponent';
import EditLocationComponent from 'components/locationadmin/EditLocationComponent';
import LocationsComponent from 'components/locationadmin/LocationComponent';

import { Map } from '../components';

const panelWidth = 450;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: '100%',
      background: theme.palette.common.white,
    },
    leftPanel: {
      width: panelWidth,
      position: 'absolute',
      left: 0,
      bottom: 0,
      top: 0,
    },
    leftContent: {
      height: '100%',
      overflow: 'auto',
      padding: 25,
    },
    rightPanel: {
      position: 'absolute',
      left: panelWidth,
      right: 0,
      bottom: 0,
      top: 0,
      height: '100%',
    },
  }),
);

const styleMarker = (f: FeatureLike) =>
  new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: f.get('meta') === 'active' ? '/map/marker-dark.png' : '/map/marker-light.png',
    }),
  });

const FSELocationAdminDesktop = () => {
  const classes = useStyles();
  const [showNewValue, setShowNew] = useState(false);
  const [selectedValue, setSelected] = useState(0);
  const [mapDispatch, setMapDispatch] = useState<{ dispatch: (command: MapCommand) => void }>();
  const containerRef = useRef<HTMLDivElement>(null);
  const { current } = containerRef;
  const view = new View({ center: fromLonLat([130, -28]), zoom: 5 });
  const { location, auth } = useAppSelector((state) => state);
  const { locations } = location;
  const dispatch = useAppDispatch();

  const getBounds = useCallback(() => {
    // 5 is a magic number; a guestimate of when we should change to the jurisdiction bounds
    if (locations.object?.length == null || locations.object?.length <= 5) {
      return config.jurisdictionBounds[getUserState(auth?.object) || 'aus'];
    }
    return {
      minLong: Math.min(...locations.object?.map((x) => x.geometry.coordinates[0])),
      minLat: Math.min(...locations.object?.map((x) => x.geometry.coordinates[1])),
      maxLong: Math.max(...locations.object?.map((x) => x.geometry.coordinates[0])),
      maxLat: Math.max(...locations.object?.map((x) => x.geometry.coordinates[1])),
    };
  }, [locations.object, auth]);

  const handleMapRegistration = (x: (command: MapCommand) => void) => {
    setMapDispatch({ dispatch: x });
  };

  useEffect(() => {
    if (locations.status === 'idle' && auth.status === 'finished') dispatch(LocationActions.getLocations());
  }, [dispatch, locations.status, auth.status]);

  useEffect(() => {
    if (locations.status === 'finished') {
      const markers = (locations.object || []).map((x) => ({
        coords: x.geometry.coordinates,
        id: x.location_id,
        meta: x.status,
      }));

      mapDispatch?.dispatch(new AddMarkers(markers, undefined, styleMarker));
      if (!locations.object) return;
      const bounds = getBounds();
      if (bounds) mapDispatch?.dispatch(new ZoomToBounds(bounds));
    }
  }, [getBounds, current, locations.object, locations.status, mapDispatch]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollToTop = () => scrollRef?.current?.scrollTo(0, 0);

  const handleLocationClick = (l: LocationManager.Location.LocationAdminAPI.Location) => {
    setSelected(l.location_id);
    scrollToTop();
    mapDispatch?.dispatch(new ZoomToCoords(l.geometry.coordinates, 15));
  };

  const handleMapClick = (id: number) => {
    const l = location?.locations?.object && location?.locations?.object.find((x) => x.location_id === id);
    if (!l) return;
    handleLocationClick(l);
  };

  const prevLocations = usePrevious({ locations });

  useEffect(() => {
    if (locations.status === 'finished' && prevLocations?.locations?.status !== 'finished') {
      const markers = (locations.object || []).map((x) => ({
        coords: x.geometry.coordinates,
        id: x.location_id,
        meta: x.status,
      }));

      mapDispatch?.dispatch(new AddMarkers(markers, handleMapClick, styleMarker));
      const bounds = getBounds();
      if (bounds) mapDispatch?.dispatch(new ZoomToBounds(bounds));
    }
  });

  const handleCancel = () => {
    setShowNew(false);
    setSelected(0);
    scrollToTop();
    const bounds = getBounds();
    if (bounds) mapDispatch?.dispatch(new ZoomToBounds(bounds));
  };

  const handleAddNew = () => {
    setShowNew(true);
    scrollToTop();
  };

  const renderLeftSection = () => {
    if (locations.status !== 'finished') {
      return <CircularProgress style={{ marginTop: 50, marginLeft: 140 }} aria-valuetext="loading" />;
    }
    if (showNewValue) return <NewLocationComponent onCancel={handleCancel} mapDispatch={mapDispatch?.dispatch} />;
    if (selectedValue) {
      const selectedLocation = locations.object?.find((x) => x.location_id === selectedValue);
      if (selectedLocation) {
        return <EditLocationComponent onCancel={handleCancel} location={selectedLocation} />;
      }
    }
    return (
      <LocationsComponent locations={locations.object || []} onShowNew={handleAddNew} onClick={handleLocationClick} />
    );
  };

  return (
    <div className={classes.root} ref={containerRef}>
      <div className={classes.leftPanel}>
        <div className={classes.leftContent} ref={scrollRef}>
          {renderLeftSection()}
        </div>
      </div>
      <div className={classes.rightPanel}>
        <Map registerMapCommand={handleMapRegistration} view={view} showUser basemap="OSM" shouldDisplay />
      </div>
    </div>
  );
};

export default FSELocationAdminDesktop;
